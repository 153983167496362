import { useLocation } from "react-router-dom";
import { incompatibleIcn } from "../../config/icons";
import { getText } from "../../helpers/translateHelper";

const IncompatiblePage = () => {

  const locData: any = useLocation()?.state;

  return (
    <div className="in-compatible-page-main pt-20 overflow-y-hidden">
      <div className="logo-ctn">
        <img src={incompatibleIcn} alt="icn" />
      </div>
      <div className="heading-ctn text-[#242735] text-24 font-bold text-center mt-20">
        {getText("Uh_OH")}
      </div>
      <div className="description-ctn flex flex-col justify-between h-[calc(100vh-305px)] px-30">
        <div className="description-text text-[#1F2436] text-14 text-center mt-8">
          <div dangerouslySetInnerHTML={{ __html: locData?.isInvalidScreen ? getText("INCOMPATIBLE_SCREEN_DESCRIPTION") : getText("INCOMPATIBLE_BROWSER_DESCRIPTION") }} />
        </div>
        <div className="text-[#242735] text-14 text-center pb-36">
          {locData?.isInvalidScreen ? getText("INCOMPATIBLE_SCREEN_BOTTOM_TEXT") : getText("INCOMPATIBLE_BROWSER_BOTTOM_TEXT")}
        </div>
      </div>
    </div>
  )
}

export default IncompatiblePage;