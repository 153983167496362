export const setLocalStorage = (key: string, value: any) => {
  localStorage.setItem(key, value);
}

export const getLocalStorage = (key: string) => {
  return localStorage.getItem(key);
}

export const removeLocalStorage = (key: string) => {
  localStorage.removeItem(key);
}

export const clearLocalStorage = () => {
  localStorage.clear();
}

export const setSessionStorage = (key: string, value: any) => {
  sessionStorage.setItem(key, value);
}

export const getSessionStorage = (key: string) => {
  return sessionStorage.getItem(key);
}

export const removeSessionStorage = (key: string) => {
  sessionStorage.removeItem(key);
}

export const clearSessionStorage = () => {
  sessionStorage.clear();
}

export const getCookieValue = (name: string) => (
  document?.cookie?.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''
)