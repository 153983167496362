import { store } from "../Redux"

/**
 * Get translated label.
 * To ensure that redux store has properly loaded, use this function inside react components only.
 * @param key label UIDs
 * @param args optional 
 */
export const getText = (key: string, ...args: string[]): string => {
  let translations = store?.getState()?.translationStore?.translations;

  if (!translations?.[key]) {
    return key;
  }

  return createLabel(translations[key], args);
}

/**
 * Create label from parameterized translation, by substituting values from args
 * @param translation parameterized translation
 * @param args optional values for substitution
 */
export function createLabel(translation: string, args: string[]) {
  let i = 0;
  return translation.replace(/\$\{[^\}]+\}/g, function () {
    const label = args?.[i++] || "";
    return label;
  });
}