import { PayloadAction } from "@reduxjs/toolkit";
import { put, select, takeLatest } from "redux-saga/effects";

import { ToastNotifyError } from "../../../components/ToastNotify";
import { ENDPOINTS } from "../../../config/endpoints";
import api from "../../../services/apiService";
import { SOME_ERR, fallbackTranslations } from "../../../data/constants";
import { callFetchAMUserDetails, callFetchAMUserFailed, callFetchAMUserSuccess, callFetchTransactions, callFetchTransactionsFailed, callFetchTransactionsSuccess, fetchSecurityQuestions, saveSecurityQuestions, setSecurityPin, setSecurityQuestion, changeAmPin, getAmCurrentSecurityQuestion, validateAmSecurityAns, validateAmDocId, forgotAmPin, unlockAmPin } from "../../slices/amSlice";
import { getPhoneNumberFromLs } from "../../../utils/commonUtils";


function* fetchAMTransactions(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response: any = yield api.request({
      method: "POST",
      url: ENDPOINTS.AM.transactionHistory(),
      data: payload.data
    });
    if (response?.data?.result?.data && response?.data?.statusCode === 200) {
      !!action?.payload?.callback && action.payload.callback(response);
      yield put(callFetchTransactionsSuccess(response.data.result.data));
    } else {
      yield put(callFetchTransactionsFailed(response));
      ToastNotifyError(fallbackTranslations[SOME_ERR])
    }
  } catch (error) {
    ToastNotifyError(fallbackTranslations[SOME_ERR])
  }
}

function* getAMUserProfile(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response: any = yield api.request({
      method: "POST",
      url: ENDPOINTS.HOME.getAMProfile(),
      data: payload.data,
    });
    if (response?.data?.result && response?.data?.statusCode === 200 && response?.status === 200) {
      yield put(callFetchAMUserSuccess(response.data));
      !!action?.payload?.callback && action.payload.callback(response.data);
    } else {
      !!action?.payload?.errorCallback && action.payload.errorCallback(response.data);
      yield put(callFetchAMUserFailed(response));
    }
  } catch (error) {
    // console.log(error);
    yield put(callFetchAMUserFailed(error));
  }
}

function* getSecurityQuestion(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response: any = yield api.request({
      method: "GET",
      url: ENDPOINTS.AM.getQuestions(),
    });
    if (response?.data?.result && response?.data?.statusCode === 200 && response?.status === 200 && !!response?.data?.result?.questions?.length) {
      yield put(saveSecurityQuestions(response.data.result.questions));
      !!payload.callback && payload.callback(response.data);
    } else {
      ToastNotifyError(response.data.message);
    }
  } catch (error) {
    console.log(error);
  }
}

function* callSetSecurityPin(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response: any = yield api.request({
      method: "POST",
      url: ENDPOINTS.AM.setPin(),
      data: payload.data,
    });
    if (response?.status === 200) {
      !!payload?.callback && payload.callback(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

function* callSetSecurityQuestion(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response: any = yield api.request({
      method: "POST",
      url: ENDPOINTS.AM.setQuestion(),
      data: payload.data,
    });
    if (response?.status === 200) {
      !!payload?.callback && payload.callback(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

function* callChangeAmPin(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response: any = yield api.request({
      method: "POST",
      url: ENDPOINTS.AM.changeAmPin(),
      data: payload.data,
    });
    if (response?.status === 200) {
      !!payload?.successCallback && payload.successCallback(response.data);
    } else {
      !!payload?.errorCallback && payload.errorCallback(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

function* getCurrSecurityQuestion(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response: any = yield api.request({
      method: "GET",
      url: ENDPOINTS.AM.getCurrentQuestion(),
    });
    if (response?.data?.statusCode === 200 && response?.status === 200) {
      !!payload.callback && payload.callback(response.data);
    } else {
      ToastNotifyError(response.data.message);
    }
  } catch (error) {
    console.log(error);
  }
}

function* callValidateSecurityAns(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response: any = yield api.request({
      method: "POST",
      url: ENDPOINTS.AM.validateSecurityAns(),
      data: payload.data,
    });
    if (response?.status === 200 && response?.data?.statusCode === 200) {
      !!payload?.successCallback && payload.successCallback(response.data);
    } else {
      !!payload?.errorCallback && payload.errorCallback(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}


function* callValidateDocId(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response: any = yield api.request({
      method: "POST",
      url: ENDPOINTS.AM.validateDocId(),
      data: payload.data,
    });
    if (response?.status === 200 && response?.data?.statusCode === 200) {
      !!payload?.successCallback && payload.successCallback(response.data);
    } else {
      !!payload?.errorCallback && payload.errorCallback(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

function* callAmForgotPin(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response: any = yield api.request({
      method: "POST",
      url: ENDPOINTS.AM.forgotPin(),
      data: payload.data,
    });
    if (response?.status === 200 && response?.data?.statusCode === 200) {
      !!payload?.successCallback && payload.successCallback(response.data);
    } else {
      !!payload?.errorCallback && payload.errorCallback(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

function* callAmUnlockPin(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response: any = yield api.request({
      method: "POST",
      url: ENDPOINTS.AM.unlockPin(),
      data: payload.data,
    });
    if (response?.status === 200 && response?.data?.statusCode === 200) {
      !!payload?.successCallback && payload.successCallback(response.data);
    } else {
      !!payload?.errorCallback && payload.errorCallback(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export default function* root(): Generator {
  yield takeLatest(callFetchTransactions.type, fetchAMTransactions);
  yield takeLatest(callFetchAMUserDetails.type, getAMUserProfile);
  yield takeLatest(fetchSecurityQuestions.type, getSecurityQuestion);
  yield takeLatest(setSecurityPin.type, callSetSecurityPin);
  yield takeLatest(setSecurityQuestion.type, callSetSecurityQuestion);
  yield takeLatest(getAmCurrentSecurityQuestion.type, getCurrSecurityQuestion);
  yield takeLatest(changeAmPin.type, callChangeAmPin);
  yield takeLatest(validateAmSecurityAns.type, callValidateSecurityAns);
  yield takeLatest(validateAmDocId.type, callValidateDocId);
  yield takeLatest(forgotAmPin.type, callAmForgotPin);
  yield takeLatest(unlockAmPin.type, callAmUnlockPin);

}