import { createSlice } from "@reduxjs/toolkit";
import { IConfigSliceProps } from "../../config/commonInterfaces";
import { clearStore } from "../actions";
import { EMPTY_APP_CONFIG, V2 } from "../../data/constants";

const initialState: IConfigSliceProps = {
  launchConfig: {
    downloadLink: "",
    phoneCode: "",
    lang: "",
    otpTimer: "",
    msisdnLength: "",
    ga4Id: "",
    betaUsers: [],
    allBetaFeatureCodes: [],
    disableRightClick: false,
    amOptions: [],
    gsmBalConfigs: [],
    appSettingsConfigs: [EMPTY_APP_CONFIG],
    helpAndSupportConfig: {
      contactUsConfigs: [],
      findUsOnConfigs: []
    },
    version: V2,
    featureMap: {},
    popularRecharges: [],
    buyGoodsConfig: null as any,
    withdrawCashConfig: {
      agentCodeRegex: "",
      agentCodeLen: 0,
      agentCodeInputMode: "numeric"
    },
    paymentMethodConfig: null as any,
    MPESAConfig: {
      remainingTimeSecs: 0.1 * 60,
      enquiryIntervalMillis: 1000,
      enquiriesAllowed: 1
    },
    showShare: false,
    sessionTimeout: 10,
    autoLogout: false,
    showExitPopup: false,
    buyBundleConfig: {
      tabsId: [],
      bundleEventObj: {} as any,
      bundleGuestEventObj: {} as any,
      bundleAllowedCategories: [],
      bundleAllowedPeriodsWithOrder: []
    },
    enableSecureSms: false,
    secureSmsNonSupportedBrowsers: [],
    secureSmsTimeout: 0,
    secureSmsDelay: 0,
    transactionLimConfig: null as any,
    amTransactionHistoryConfig: null as any,
    supportedBrowsers: {},
    minimumScreenWidth: "",
    noAuthCheckRoutes: [],
    numberOfFetchAMTransactions: 0,
    locateUsLink: "",
    languageConfig: {
      supportedLanguages: []
    },
    heInitUrl: "",
    autoHeInit: false,
    heMandatory: true,
    heUnsupportedBrowser: {},
    isHeDisable: true,
    heOtpFlowEnable: true,
    isTempPinEnabledInOPCO: false,
    isDisableOtpPaste: true,
    isChangePin: false,
    guestConfig: {
      isEnabled: false,
      remainingTimeSecs: 0.1 * 60,
      enquiryIntervalMillis: 1000,
      enquiriesAllowed: 1
    },
    securityQuestions: {
      ansMinLength: 4,
      ansMaxLength: 16
    },
    betaFeatureMap: {},
    ussd_am_code: "",
    enableUnlockPin: true
  },
  basicConfig: {
    customerCareNo: "",
    msisdnLength: "",
    backgroundThreadFreq: "",
    currency: "",
    ussdGSMCode: "",
    ussdAMCode: "",
    otpAttempts: "",
    recentContactsCount: "",
    invalidAMPinStatuses: [],
    bundleType: [],
    rechargeAirtimeFav: undefined,
    buyBundleFav: undefined,
    sendMoneyFav: undefined,
    ussdPayBillCode: ""
  },

  limitConfig: {
    airtimeRecharge: {
      min: "",
      max: ""
    },
    sendMoneyLocal: {
      min: "",
      max: ""
    },
    withdrawCashLimit: {
      min: 0,
      max: 0,
    }
  },
  homeConfig: [],
  homeConfigTimestamp: "",
  balanceConfig: []
};

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    callConfigApi: (state, action) => {
    },
    configApiSucess: (state, action) => {
    },
    configApiFailed: (state, action) => {
    },

    //launch config
    callLaunchConfigApi: (state, action) => {

    },
    launchConfigApiSucess: (state, action) => {
      if (!state.launchConfig) {
        state.launchConfig = { ...initialState.launchConfig };
      }
      const configData = action.payload?.data?.[0];
      state.launchConfig = { ...configData };
      state.launchConfig = {
        ...state.launchConfig,
        lang: configData?.locale || "",
        phoneCode: configData?.phone_code,
        otpTimer: configData?.otp_timer,
        msisdnLength: configData?.msisdn_length,
        betaUsers: configData?.beta_users,
        disableRightClick: configData?.disable_right_click,
        featureMap: configData.feature_map,
        securityQuestions: {
          ansMinLength: configData?.securityQuestions?.ansMinLength ?? 4,
          ansMaxLength: configData?.securityQuestions?.ansMaxLength ?? 16
        }
      }

      // basic config data at launch has been addded here to the store state
      if (!state.basicConfig) {
        state.basicConfig = { ...initialState.basicConfig };
      }
      state.basicConfig = {
        ...state.basicConfig,
        customerCareNo: configData?.customer_care_no,
        msisdnLength: configData?.msisdn_length,
        backgroundThreadFreq: configData?.background_thread_freq,
        currency: configData?.currency,
        ussdGSMCode: configData?.ussd_gsm_code,
        ussdAMCode: configData?.ussd_am_code,
        otpAttempts: configData?.otp_attempts,
        bundleType: configData?.bundleType,
        invalidAMPinStatuses: configData?.invalidAMPinStatuses,
        rechargeAirtimeFav: configData?.rechargeAirtimeFav,
        buyBundleFav: configData?.buyBundleFav,
        sendMoneyFav: configData?.sendMoneyFav,
        ussdPayBillCode: configData?.ussdPayBillCode,
        recentContactsCount: state?.basicConfig?.recentContactsCount && configData?.recentContactsCount,
      }

      // limit config data at launch has been addded here to the store state
      if (!state.limitConfig) {
        state.limitConfig = { ...initialState.limitConfig };
      }
      state.limitConfig = {
        ...state.limitConfig,
        airtimeRecharge: configData?.airtime_recharge,
        sendMoneyLocal: configData?.send_money_local,
        withdrawCashLimit: configData?.withdrawCashLimit,
      }
    },
    launchConfigApiFailed: (state, action) => {

    },
  },
  extraReducers: (builder) => {
    builder //clear config store
      .addCase(clearStore, (state, action) => {
        return initialState;
      })?.addDefaultCase((state, action) => { })
  }
});

export const {
  callConfigApi,
  configApiSucess,
  configApiFailed,
  callLaunchConfigApi,
  launchConfigApiSucess,
  launchConfigApiFailed,
} = configSlice.actions;
export default configSlice.reducer;
