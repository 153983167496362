import { PayloadAction } from "@reduxjs/toolkit";
import { select, takeLatest } from "redux-saga/effects";
import { ToastNotifyError } from "../../../components/ToastNotify";
import { ENDPOINTS } from "../../../config/endpoints";
import api from "../../../services/apiService";
import * as withdrawCashSlice from "../../slices/withdrawCashSlice";
import { SOME_ERR } from "../../../data/constants";
import { getText } from "../../../helpers/translateHelper";

function* getAgentInfo(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response: any = yield api.request({
      method: "POST",
      url: ENDPOINTS.HOME.getAMProfile(),
      data: payload.data,
    });
    if (!!response?.data && response?.data?.statusCode === 200) {
      !!action?.payload?.callback && action.payload.callback(response?.data?.result);
    }
    else {
        action?.payload?.setErrorMessage?.(response?.data?.message);
    }
  } catch (error) {
    ToastNotifyError(getText(SOME_ERR));
    console.log("Something went wrong", error);
  }
}


export default function* root(): Generator {
  yield takeLatest(withdrawCashSlice.callGetAgentInfo.type, getAgentInfo);
}
