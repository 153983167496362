import { createSlice } from "@reduxjs/toolkit";
import { clearStore } from "../actions";
import moment from "moment";

interface IAmSlice {
  transactions: any[];
  isHistoryFetched: boolean;
  isAmMasked: boolean;
  amUnmaskedTimeStamp: string;
  isAMTranscationMasked: boolean;
  amTransactionTimestamp: string;
  securityQuestions: string[];
}

const initialState: IAmSlice = {
  transactions: [],
  isHistoryFetched: false,
  isAmMasked: true,
  amUnmaskedTimeStamp: "",
  isAMTranscationMasked: true,
  amTransactionTimestamp: "",
  securityQuestions: []
}

export const amSlice = createSlice({
  name: "amStore",
  initialState,
  reducers: {
    callFetchTransactions: (state, action) => { },
    callFetchTransactionsSuccess: (state, action) => {
      state.transactions = action.payload;
      state.isHistoryFetched = true;
    },
    callFetchTransactionsFailed: (state, action) => {
      state.isHistoryFetched = false;
      state.transactions = [];
    },
    callFetchAMUserDetails: (state, action) => { },
    callFetchAMUserSuccess: (state, action) => { },
    callFetchAMUserFailed: (state, action) => { },
    setMaskedAmBalance: (state, action) => {
      state.isAmMasked = action.payload;
      if (!action.payload)
        state.amUnmaskedTimeStamp = moment().format();
    },
    setMaskedAmTransaction: (state, action) => {
      state.isAMTranscationMasked = action.payload;
      if (!action.payload)
        state.amTransactionTimestamp = moment().format();
    },
    fetchSecurityQuestions: (state, action) => { },
    saveSecurityQuestions: (state, action) => {
      state.securityQuestions = action.payload;
    },
    setSecurityPin: (state, action) => { },
    setSecurityQuestion: (state, action) => { },
    changeAmPin: (state, action) => { },
    getAmCurrentSecurityQuestion: (state, action) => { },
    validateAmSecurityAns: (state, action) => { },
    validateAmDocId: (state, action) => { },
    forgotAmPin: (state, action) => { },
    unlockAmPin: (state, action) => { }
  },
  extraReducers: (builder) => {
    builder //clear home store
      .addCase(clearStore, (state) => {
        return initialState;
      })
      .addDefaultCase((state, action) => { })
  }
});

export const {
  callFetchTransactions,
  callFetchTransactionsSuccess,
  callFetchTransactionsFailed,
  callFetchAMUserDetails,
  callFetchAMUserFailed,
  callFetchAMUserSuccess,
  setMaskedAmBalance,
  setMaskedAmTransaction,
  saveSecurityQuestions,
  fetchSecurityQuestions,
  setSecurityPin,
  setSecurityQuestion,
  changeAmPin,
  getAmCurrentSecurityQuestion,
  validateAmSecurityAns,
  validateAmDocId,
  forgotAmPin,
  unlockAmPin
} = amSlice.actions;

export default amSlice.reducer;