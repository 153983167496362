import { createSlice } from "@reduxjs/toolkit";
import { clearStore } from "../actions";

export interface IWithdrawCashResponse {
  txnId: string;
  status: boolean;
  message: string;
  showRateMe: boolean;
  maxTransactionTime: number;
  timeDelay: number;
  statusCode: string;
  showMarkAsFavourite: boolean;
  authorizationRequired: boolean;
  showPGTimer: boolean;
  poolingRequired: boolean;
  isUserBlocked: boolean;
  recipientNumber: string;
  amountSend: string;
  transactionDateAndTime: string;
}

const initialState = {
}

export const withdrawCashSlice = createSlice({
  name: "withdrawCash",
  initialState,
  reducers: {


    //call get recepient am user profile
    callGetAgentInfo: (state, action) => { },
    callWithdrawCashSuccess: (state, action) => { },

    callWithdrawCashFailure: (state, action) => { },
   
  },
  extraReducers: (builder) => {
    builder //clears everything on 401
      .addCase(clearStore, (state) => {
        return initialState;
      })
      .addDefaultCase((state, action) => { })
  }
});

export const {
    callGetAgentInfo,
    callWithdrawCashSuccess,
    callWithdrawCashFailure
} = withdrawCashSlice.actions;

export default withdrawCashSlice.reducer;
