import moment from "moment";
import * as codes from "../../../config/codes";
import { SMS, BALANCE_DT_FORMAT } from "../../../data/constants";
import {
  IBundleBalance,
  IBundleCategoryBalance,
  ICompositeBalance,
  IInputGSMBalance,
  ISimpleBalance,
  IVoiceBalance,
} from "../config/interface";
import { sortISimpleBalanceDetailsByDateTime } from "./balanceUtils";

// maps gsm balance from payload to simpler format
export function toICompositeBalance(
  inputGSMBalance: IInputGSMBalance
): ICompositeBalance {
  let amBal: ISimpleBalance | undefined = undefined;

  let balance: ICompositeBalance = {
    smsBal: toSMSBalance(inputGSMBalance),
    dataBal: toSortedDataBalance(inputGSMBalance),
    voiceBal: toSortedVoiceBalance(inputGSMBalance),
    airtimeBal: toAirtimeBalance(inputGSMBalance),
    amBal,
    timestamp: undefined,
    refreshGsmBal: false,
    offlineTimeStamp: undefined,
    isAmMasked: true,
    amTimeStamp: "",
    bundlebyCategory: {},
    activePacks: [],
    isFetchingGSMBalance: false,
  };

  return balance;
}

// maps gsm balance payload to sms balance
function toSMSBalance(inputGSMBalance: IInputGSMBalance) {
  let smsBal = undefined;
  inputGSMBalance?.totalBalance?.some((it) => {
    if (it?.unit === SMS) {
      smsBal = {
        total: `${it?.total ? parseInt(it.total) : ""} ${it?.unit}`,
        value: it?.total,
        unit: it?.unit
      } as ISimpleBalance;
      return true;
    }
    return false;
  });
  return smsBal;
}

// maps gsm balance payload to airtime balance
//without currency
function toAirtimeBalance(inputGSMBalance: IInputGSMBalance) {
  if (inputGSMBalance?.airtimeBalanceInfo?.total !== undefined) {
    return {
      total: "" + inputGSMBalance?.airtimeBalanceInfo?.total
    } as ISimpleBalance;
  }
  return undefined;
}

// maps gsm balance payload to voice balance
// we will get sorted data
export function toSortedVoiceBalance(inputGSMBalance: IInputGSMBalance) {
  let voiceBundle = inputGSMBalance?.bundlebyCategory?.voiceBundle;
  return {
    onnet: getSortedVoiceCategoryBalance(
      voiceBundle,
      codes.balanceState.VOICE_CATEGORY_ONNET
    ),
    offnet: getSortedVoiceCategoryBalance(
      voiceBundle,
      codes.balanceState.VOICE_CATEGORY_OFFNET
    ),
    total: inputGSMBalance?.totalBalance?.find((it) => it?.bundleType === 1)?.total
  } as IVoiceBalance;
}

// get onnet balance or offnet balance from voice bundle balance
//sort by expiry
const getSortedVoiceCategoryBalance = (
  voiceBundle: IBundleCategoryBalance,
  categoryName: string
): ISimpleBalance | undefined => {
  let voiceCategory = undefined;

  voiceBundle?.allBundles?.some((it) => {
    if (it?.title !== categoryName) {
      return false;
    }

    voiceCategory = {
      total: `${it?.total ? it.total : "0"}`,
      details: it?.bundleList?.map((bundle) => {
        return {
          key: bundle?.title,
          value: {
            balance: `${bundle?.total}`,
            expiry: moment(bundle?.expiry).format(BALANCE_DT_FORMAT),
          },
        };
      }),
    };

    if (voiceCategory?.details)
      voiceCategory.details = sortISimpleBalanceDetailsByDateTime(voiceCategory.details, BALANCE_DT_FORMAT);

    return true;
  });

  if (!voiceCategory) {
    voiceCategory = {
      total: "0",
      value: "0",
      unit: ""
    };
  }
  return voiceCategory;
};

// maps gsm balance payload to data balance
//sorts the data by expiry
// ASSUMPTION only 1 data bundle sub category
export function toSortedDataBalance(
  inputGSMBalance: IInputGSMBalance
): ISimpleBalance | undefined {
  let dataBundle = inputGSMBalance?.bundlebyCategory?.dataBundle;

  let dataBal: ISimpleBalance = { total: "", value: "", unit: "", details: undefined };

  inputGSMBalance?.totalBalance?.some((it) => {
    if (it?.bundleType === 2) {
      dataBal.total = `${it?.total || ""} ${it?.unit}`;
      dataBal.value = it?.total || "";
      dataBal.unit = it?.unit || "";
    }
  });

  if (!dataBal?.total)
    return undefined;
  //should run once
  dataBundle?.allBundles?.some((it) => {
    dataBal["details"] = toISimpleBalanceDetails(it?.bundleList);

    if (dataBal?.details)
      dataBal.details = sortISimpleBalanceDetailsByDateTime(dataBal.details, BALANCE_DT_FORMAT);
  });

  return dataBal;
}

//payload bundle balance array to ISimpleBundleBalance details
function toISimpleBalanceDetails(
  bundles: IBundleBalance[]
): ISimpleBalance["details"] | undefined {
  return bundles?.map((bundle) => {
    return {
      key: bundle?.title,
      value: {
        balance: `${bundle?.total} ${bundle?.unit}`,
        expiry: moment(bundle?.expiry).format(BALANCE_DT_FORMAT),
      },
    };
  });
}
