import { PayloadAction } from "@reduxjs/toolkit";
import { put, takeLatest, select } from "redux-saga/effects";
import { ToastNotifyError } from "../../../components/ToastNotify";
import { ENDPOINTS } from "../../../config/endpoints";
import api from "../../../services/apiService";
import { callGetSecureSmsToken, callResendOtp, callResendOtpFailed, callResendOtpSuccess, callSendOtp, callSendOtpFailed, callSendOtpSuccess, callVerifyOtp, callVerifyOtpFailed, callVerifyOtpSuccess, callVerifySSmsToken } from "../../slices/loginSlice";
import { AxiosResponse } from "axios";

function* sendOtp(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response = (yield api.request({
      method: "POST",
      url: ENDPOINTS.LOGIN.sendOtp(),
      data: payload.data
    })) as AxiosResponse;
    if (!!response?.data && response?.status === 200) {
      yield put(callSendOtpSuccess(response.data));
      !!action?.payload?.callback && action.payload.callback(response.data);
    } else {
      yield put(callSendOtpFailed(response));
      !!action?.payload?.callback && action.payload.callback(response.data);
    }
  } catch (error) {
    console.log(error);
    // ToastNotifyError("Something went wrong");
    yield put(callSendOtpFailed(error));
  }
}

function* verifyOtp(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response: any = yield api.request({
      method: "POST",
      url: ENDPOINTS.LOGIN.verifyOtp(),
      data: payload.data
    });
    if (!!response?.data && response?.status === 200) {
      yield put(callVerifyOtpSuccess(response.data));
      !!action?.payload?.callback && action.payload.callback(response.data);
    } else {
      ToastNotifyError(response?.data?.message);
      yield put(callVerifyOtpFailed(response));
      !!action?.payload?.callback && action.payload.callback(response.data);
    }
  } catch (error) {
    console.log(error);
    yield put(callVerifyOtpFailed(error));
  }
}

function* resendOtp(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response: any = yield api.request({
      method: "POST",
      url: ENDPOINTS.LOGIN.resendOTP(),
      data: payload.data
    });
    if (!!response?.data && response?.status === 200) {
      yield put(callResendOtpSuccess(response.data));
      !!action?.payload?.callback && action.payload.callback(response.data);
    } else {
      ToastNotifyError(response?.data?.message);
      yield put(callResendOtpFailed(response));
      !!action?.payload?.callback && action.payload.callback(response.data);
    }
  } catch (error) {
    console.log(error);
    yield put(callResendOtpFailed(error));
  }
}

function* getSecureSmsToken(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response: any = yield api.request({
      method: "POST",
      url: ENDPOINTS.LOGIN.secureSmsToken(),
      data: payload.data
    });
    if (!!response?.data) {
      !!action?.payload?.callback && action.payload.callback(response.data);
    } else {

    }
  } catch (error) {
    console.log(error);
  }
}

function* verifySecureSmsToken(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response: any = yield api.request({
      method: "POST",
      url: ENDPOINTS.LOGIN.secureSmsVerifyToken(),
      data: payload.data,
      hideLoader: payload.hideLoader
    });
    if (!!response?.data) {
      !!payload?.callback && payload.callback(response.data);
    } else {

    }
  } catch (error) {
    console.log(error);
  }
}

export default function* root(): Generator {
  yield takeLatest(callSendOtp.type, sendOtp);
  yield takeLatest(callVerifyOtp.type, verifyOtp);
  yield takeLatest(callResendOtp.type, resendOtp);
  yield takeLatest(callGetSecureSmsToken.type, getSecureSmsToken);
  yield takeLatest(callVerifySSmsToken.type, verifySecureSmsToken);
}