import React, { ErrorInfo, ReactNode } from 'react';
import icons from '../../config/icons';
import { getText } from '../../helpers/translateHelper';
import { OOPS, REFRESH_NOW, SOME_ERR } from '../../data/constants';
import ErrorBoundaryUI from './ErrorBoundaryUI';
import { HOME_PAGE } from '../../routes/routeConstants';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Error caught by ErrorBoundary:", error, errorInfo);
  }

  getRefresh = () => {
    window.location.pathname = HOME_PAGE;
  };

  render() {
    if (this.state.hasError) {
      // Fallback UI for the error
      <ErrorBoundaryUI refreshFunc={this.getRefresh} />
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
