import { createSlice } from "@reduxjs/toolkit";
import { clearStore } from "../actions";

export interface IAppSettingsSlice {
  /**
   * code will be same as label present in launch config appSettingsConfig
   */
  staticPagesObj: Record<string, string>,
}

const initialState: IAppSettingsSlice = {
  staticPagesObj: {},
};

export const appSettingsSlice = createSlice({
  name: "appSettingsStore",
  initialState,
  reducers: {
    //to trigger saga
    callGetStaticPage: (state, action) => {
    },
    //on get static page success
    callGetStaticPageSuccess: (state, action) => {
      if (!state?.staticPagesObj)
        state.staticPagesObj = {};
      state.staticPagesObj[action.payload.code] = action.payload.data;
    },
    //to trigger logout saga
    callLogout: (state, action) => {
    }
  },
  extraReducers: (builder) => {
    builder //clear home store
      .addCase(clearStore, (state) => {
        return initialState;
      })
      .addDefaultCase((state, action) => { })
  }
});

export const {
  callLogout,
  callGetStaticPage,
  callGetStaticPageSuccess
} = appSettingsSlice.actions;
export default appSettingsSlice.reducer;
