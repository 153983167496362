import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { store } from "./Redux/index";
import "./index.scss";
import AppRoutes from "./routes/routes";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import HomeLayout from "./layout/HomeLayout";
import packageJson from "../package.json";
import 'core-js/actual';
import ErrorBoundary from "./components/ErrorBoundary";

// serviceWorkerRegistration.register();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

console.log(`%cAirtel - Applite Team(v${packageJson.version}) ⓒ ${(new Date).getFullYear()}`, "color: #e40000; font-size: 18px; font-weight: bold")

root.render(
  <ErrorBoundary>
    <BrowserRouter basename="/">
      <Provider store={store}>
        <HomeLayout>
          <ToastContainer theme="dark" />
          <AppRoutes />
        </HomeLayout>
      </Provider>
    </BrowserRouter>
  </ErrorBoundary>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

serviceWorkerRegistration.register();