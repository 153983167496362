import icons from "../../config/icons";
import { OOPS, REFRESH_NOW, SOME_ERR } from "../../data/constants";
import { getText } from "../../helpers/translateHelper";

const ErrorBoundaryUI = ({ refreshFunc }: any) => {
  return (
    <>
      <div className="initial_error flex items-center h-[100vh] justify-center px-[40px] fixed top-0 bottom-0 left-0 right-0 z-50 bg-white">
        <div className="text-center">
          <img
            className="mb-[40px] mx-auto"
            src={icons.oops}
            alt={icons.oops}
          />
          <h3 className="font-[700] text-30 mb-6"> {getText(OOPS)} </h3>
          <p className=" text-16 mb-50 px-[20px]"> {getText(SOME_ERR)}</p>
          <button
            onClick={refreshFunc}
            className="bg-[#1F2436] text-[#fff] font-[600] text-center text-14 py-[14px] rounded-[4px] w-[100%] block"
          >
            {getText(REFRESH_NOW)}
          </button>
        </div>
      </div>
    </>
  );
}

export default ErrorBoundaryUI;