import { useEffect } from "react";
import "./style.scss";
const Loader = () => {

  useEffect(() => {
    document.body.style.position = 'fixed';
    return (() => {
      document.body.style.position = 'static';
    });
  }, []);

  return (
    <div className="loader-container">
      <div className="spinner"></div>
    </div>
  )
}

export default Loader;