import { store } from "../../../Redux"

export const getTransactionLabel = (txn: any, type: string) => {
    const serviceTypes = store.getState()?.config?.launchConfig?.amTransactionHistoryConfig?.serviceTypes?.[type];
    let obj = {
        label: txn.transactionType === 'MR' ? "RECEIVED_FROM" : "PAID_TO",
        name: (txn.transactionType === 'MR') ? `${txn.payerName}` : `${txn.payeeName}` 
    }

    if(serviceTypes && serviceTypes?.[txn?.serviceType] && serviceTypes?.[txn?.serviceType]?.[txn.entryType])
        obj.label = serviceTypes?.[txn?.serviceType]?.[txn.entryType]

    return obj;
}
