import { useState, useEffect } from 'react';
import { RootState, store } from '../Redux';
import { getAuthTokenFromLs, getPhoneNumberFromLs } from '../utils/commonUtils';
import { callCheckGSMBalance } from '../Redux/slices/balanceSlice';
import { useSelector } from 'react-redux';

const useBackgroundBalanceFetch = () => {
    const [timer, setTimer] = useState<number>(6 * 60 * 60 * 1000), //6 hours
        balance = useSelector((state: RootState) => state.balance.balance) || {};

    useEffect(() => {
        let payload = {
            data: {
                msisdn: getPhoneNumberFromLs(),
                lob: "",
            },
            callback: () => { },
        };
        const interval = setInterval(() => {
            store.dispatch(callCheckGSMBalance(payload));
        }, timer);
        if (!!getAuthTokenFromLs() && !balance.timestamp)
            store.dispatch(callCheckGSMBalance(payload));
        return () => clearInterval(interval);
    }, []);

    return { timer };
};

export default useBackgroundBalanceFetch;