import * as routes from "./routeConstants";

export const skeletonBlock: any = {
  getskeletonLogin: ["/"],
  getskeletonHead: [
    routes.BUY_GOODS_RT,
    routes.HOME_PAGE,
    routes.balanceRoute.BALANCE,
    routes.airtelMoney.TRANSACTION_HISTORY,
  ],
  getskeletontwoBx: [routes.HOME_PAGE, routes.balanceRoute.BALANCE],
  getskeletonCardList: [
    routes.airtelMoney.TRANSACTION_HISTORY,
    routes.STATIC_APP_SETTINGS_ROUTE,
    routes.HELP_AND_SUPPORT_ROUTE,
    routes.PAYMENT_METHODS_ROUTE,
  ],
  getskeletonFormList: [
    routes.HOME_PAGE,
    routes.recharge.RECHARGE,
    routes.SEND_MONEY,
    routes.BUY_GOODS_RT,
    routes.WITHDRAW_CASH,
  ],
};

export const getSkeletonBlock = () => {
  const path = window.location.pathname;
  const blockObj: any = {};
  for (const block in skeletonBlock) {
    const ind = skeletonBlock[block].indexOf(path);
    if (ind !== -1) blockObj[block] = true;
  }
  return blockObj;
};
