// CustomModal.tsx

import React, { FC, useRef, useEffect } from 'react';

interface ModalProps {
  isOpen: boolean;
  onClose?: () => void;
  children: React.ReactNode;
  className?: string;
}

const Modal: FC<ModalProps> = ({ isOpen, onClose, children, className }) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const handleOutsideClick = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      onClose && onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
      document.body.style.position = 'fixed';
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.body.style.position = 'static';
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal-main  fixed inset-0 z-50 flex items-center justify-center h-[100vh]">
      <div className="modal-overlay absolute inset-0 bg-black opacity-50 pointer-events-none"></div>
      <div className={`modal-body z-50 w-full rounded-md bottom-0 left-0 right-0 fixed ${className}`} ref={modalRef}>
        {children}
      </div>
    </div>
  );
};

export default Modal;
