import { useSelector } from "react-redux";
import { RootState } from "../../Redux";
import { useRef, useState } from "react";
import useScreenshot from "../../hooks/useScreenshoot";
import { startLoader, stopLoader } from "../../helpers/commonHelpers";
import { checkIos } from "../../utils/commonUtils";
import icons from "../../config/icons";
import SuccessIcon from "../../components/success-icon";
import { getText } from "../../helpers/translateHelper";
import { getTransactionLabel } from "../../features/AMTransactionHistory/helpers/AMTransactionHistoryHelper";
import TransactionSummary from "../../components/TransactionDetails/TransactionSummary";
import { useLocation, useNavigate } from "react-router-dom";
import { airtelMoney } from "../../routes/routeConstants";

const TransactionDetailsPage = () => {
  let data = useLocation().state;
  const currency = useSelector((state: RootState) => state.config?.basicConfig?.currency),
    [showShareBtn, setShowShareBtn] = useState<boolean>(true);
  let recipientNumberKey = data.entryType === "CR" ? "senderNumber" : "recipientNumber";
  let recipientNameKey = data.entryType === "CR" ? "senderName" : "recipientName";

  const mappedData = {
    "totalAmount": data.amount,
    "transactionDateAndTime": data.transactionDate,
    "txnId": data.transactionId,
    [recipientNameKey]: data.entryType === 'CR' ? `${data.payerName}` : `${data.payeeName}`,
    [recipientNumberKey]: data.entryType === 'CR' ? `${data.payerMsisdn}` : `${data.payeeMsisdn}`
  },
    ref = useRef(null),
    { image, takeScreenshot }: any = useScreenshot({ ref }),
    showShare = useSelector((state: RootState) => state.config?.launchConfig?.showShare),
    navigate = useNavigate();


  const canShare = 'canShare' in navigator && showShare;

  async function getSc() {
    let img = await takeScreenshot();
    shareOrDownload(img, "screenshot.jpg");
  }

  const shareOrDownload = async (shareimg: any, fileName: any) => {
    if (canShare) {
      startLoader();
      try {
        const response = await fetch(shareimg);
        const blob = await response.blob();
        const file = new File([blob], fileName, { type: blob.type });

        await navigator.share({
          files: [file]
        });
        stopLoader();
      } catch (err: any) {
        console.log(err.name, err.message);
        stopLoader();
      }
    }
  }

  function gotoBack() {
    navigate(-1);
  }

  return (
    <div className="transaction-detail-popup bg-[#FFFFFF] h-[100vh] w-[100vw] z-[10001] overflow-auto pt-[80px] pb-[40px]">
      <div className="back-icn left-16 top-0 fixed">
        <img className="mt-24" onClick={gotoBack} src={icons.backIcn} alt="" />
      </div>
      {canShare && !checkIos() ?
        <div className="share-cta text-right mr-16 right-16 fixed top-30" onClick={getSc}>
          <img className="w-16" src={icons.shareIcn} alt="shar-icn" />
        </div>
        :
        null
      }
      <div className="transaction-details pt-20 bg-[#FFFFFF] pb-20" ref={ref}>
        <span className="h-[74px] w-[74px] bg-[#eee] flex items-center justify-center rounded-[100%] m-auto shadow-[0_0px__0px_16px_#f6f6f6]">
          <SuccessIcon />
        </span>
        <div className="message mt-40 text-[#242224] text-center text-14">
          <span dangerouslySetInnerHTML={{ __html: getText(getTransactionLabel(data, 'detail')?.label, data.amount, currency) }} />
          <span className="font-bold ml-[5px]">{getTransactionLabel(data, 'detail')?.name}</span>
        </div>
        <div className="transaction-summary mt-30">
          <TransactionSummary data={mappedData} />
        </div>
      </div>
    </div>
  )
}

export default TransactionDetailsPage;