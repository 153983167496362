export const GSM = "gsm_balance";
export const AM = "am_balance";
export const AM_PIN = "am_pin";

/**
 * Constant containing the feature codes
 * Object value: feature name saved in backend and launch config api
*/
export const featureNames = {
  CHECK_BALANCE: "check_balance",
  BUY_BUNDLE: "buy_bundle",
  RECHARGE_AIRTIME: "recharge_airtime",
  SEND_MONEY: "send_money",
  PAY_BILL: "PayBill",
  FAVOURITE: "favourites",
  MY_FAV: "favourites"
};

export const balanceState = {
  DATA: "data_balance",
  VOICE_MENU: "voice_balance",
  VOICE_ONNET: "onnet_voice_balance",
  VOICE_OFFNET: "offnet_voice_balance",
  SMS: "sms_balance",
  AIR_TIME: "airtime_balance",
  AIRTEL_MONEY: "am_balance",
  AIRTEL_MONEY_PIN: "am_balance",
  VOICE_CATEGORY_ONNET: "Airtel to Airtel",
  VOICE_CATEGORY_OFFNET: "All Network",
};

export const recharge = {
  SELF: "self",
  OTHERS: "others",
  payment: {
    AM: "am",
    AIRTIME: "airtime",
  },
};

export const buyBundle = {
  SELF: "bundle_self",
  OTHERS: "bundle_others",
  payment: {
    AM: "am",
    AIRTIME: "airtime",
  },
};


export const homeFunctions = [
  {
    "code": featureNames.CHECK_BALANCE,
    "name": "CHECK_BALANCE",
    "featureId": 159
  },
  {
    "code": featureNames.BUY_BUNDLE,
    "name": "BUY_BUNDLE",
    "featureId": 160
  },
  {
    "code": featureNames.RECHARGE_AIRTIME,
    "name": "RECHARGE_AIRTIME",
    "featureId": 163
  },
  {
    "code": featureNames.SEND_MONEY,
    "name": "SEND_MONEY",
    "featureId": 159
  },

  {
    "code": featureNames.PAY_BILL,
    "name": "PAY_BILL",
    "featureId": 164
  },
  {
    "code": featureNames.MY_FAV,
    "name": "MY_FAV",
    "featureId": 165
  }
];

export const checkBalanceFunctions = [
  {
    "code": "data_balance",
    "name": "DATA",
    "featureId": 164
  },
  {
    "code": "voice_menu",
    "name": "VOICE",
    "featureId": 165
  },
  {
    "code": "sms_balance",
    "name": "SMS",
    "featureId": 166
  },
  {
    "code": "air_time_balance",
    "name": "AIRTIME",
    "featureId": 167
  },
  {
    "code": "airtel_money_balance_pin",
    "name": "AIRTEL_MONEY",
    "featureId": 168
  }
];
