import { store } from "../../../Redux";
import { saveParsedBundles } from "../../../Redux/slices/bundleSlice";
import { ACTION, IPaymentOptionsLocData } from "../../../config/commonInterfaces";

export const parseBundleData = (data: any = []) => {
  let bundle: any = {};
  data.forEach((category: any) => {
    if (category?.bundles?.length) {
      let bundles = category.bundles;
      for (let i = 0; i < bundles.length; i++) {
        if (!bundle?.[bundles?.[i]?.bundleType]) {
          bundle[bundles?.[i]?.bundleType] = [];
          bundle[bundles?.[i]?.bundleType] = [...bundles?.[i]?.packs];
        } else {
          bundle[bundles?.[i]?.bundleType] = [...bundle[bundles?.[i]?.bundleType], ...bundles?.[i]?.packs];
        }
      }
    }
  });
  bundle = parseBundleByCategory(bundle);
  bundle = sortBundleByHighToLowPrice(bundle);
  store.dispatch(saveParsedBundles(bundle));
}

export const parseBundleByCategory = (data: any = {}) => {
  let bundles: any = {}, bundleTypes = Object.keys(data);
  if (bundleTypes.length) {
    bundleTypes.forEach((type) => {
      if (data[type].length) {
        bundles[type] = {};
        for (let i = 0; i < data[type].length; i++) {
          if (bundles?.[type]?.[data?.[type]?.[i]?.categoryName]) {
            bundles[type][data[type][i].categoryName].push(data[type][i]);
          } else {
            bundles[type][data[type][i].categoryName] = [];
            bundles[type][data[type][i].categoryName].push(data[type][i]);
          }
        }
      }
    })
  }
  return bundles;
}

/*
This function is used to short the bundles by high to low price
*/
export const sortBundleByHighToLowPrice = (data: any = {}) => {
  let result: any = {};
  if (!!Object?.keys(data)?.length) {
    Object.keys(data).forEach((bundleType) => {
      result[bundleType] = {};
      Object.keys(data[bundleType]).forEach((category) => {
        result[bundleType][category] = data[bundleType][category].sort((a: any, b: any) => {
          return b.price - a.price;
        })
      })
    })
  }
  return result;
}

export function getBundleEvent(action: ACTION, locData : IPaymentOptionsLocData) : string {
  const bundleEventObj = store.getState()?.config?.launchConfig?.buyBundleConfig?.bundleEventObj;
  const category = locData?.data?.bundleCategory?.toLowerCase() ?? null
  return category ? bundleEventObj?.[category]?.[action] : ""
}