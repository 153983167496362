import { createSlice } from "@reduxjs/toolkit";
import { clearStore } from "../actions";

export interface Props {
  isSendOtpCall: boolean;
  isVerifyOtpCall: boolean;
  isPollingStarted: boolean;
}

const initialState: Props = {
  isSendOtpCall: false,
  isVerifyOtpCall: false,
  isPollingStarted: false,
}

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    callSendOtp: (state, action) => {

    },
    callSendOtpSuccess: (state, action) => {

    },
    callSendOtpFailed: (state, action) => {

    },
    callVerifyOtp: (state, action) => {
      state.isVerifyOtpCall = true;
    },
    callVerifyOtpSuccess: (state, action) => {
      state.isVerifyOtpCall = false;
    },
    callVerifyOtpFailed: (state, action) => {
      state.isVerifyOtpCall = false;
    },
    callResendOtp: (state, action) => {

    },
    callResendOtpSuccess: (state, action) => {

    },
    callResendOtpFailed: (state, action) => {

    },
    callGetSecureSmsToken: (state, action) => {
    },
    callVerifySSmsToken: (state, action) => {
    },
    callVerifySSmsTokenSuccess: (state, action) => {
    },
    callVerifySSmsTokenFailed: (state, action) => {
    },
    startSecureSmsPolling: (state, action) => {
      state.isPollingStarted = true;
    },
    stopSecureSmsPolling: (state, action) => {
      state.isPollingStarted = false;
    },
  },
  extraReducers: (builder) => {
    builder //clears everything on 401
      .addCase(clearStore, (state) => {
        return initialState;
      })
      .addDefaultCase((state, action) => { })
  }
});

export const {
  callSendOtp,
  callSendOtpSuccess,
  callSendOtpFailed,
  callVerifyOtp,
  callVerifyOtpSuccess,
  callVerifyOtpFailed,
  callResendOtp,
  callResendOtpSuccess,
  callResendOtpFailed,
  callGetSecureSmsToken,
  callVerifySSmsToken,
  callVerifySSmsTokenSuccess,
  callVerifySSmsTokenFailed,
  startSecureSmsPolling,
  stopSecureSmsPolling,
} = loginSlice.actions;

export default loginSlice.reducer;