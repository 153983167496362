import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const customId = "custom-id-yes";

const options = {
    hideProgressBar: true,
    position: toast.POSITION.BOTTOM_CENTER,
    autoClose: 3000,
    closeOnClick: true,
    draggable: false,
    toastId: customId,
};
const optionsInfo = {
    type: toast.TYPE.INFO,
    ...options,
    icon: false,
    className: "toast-info"
};
const optionsSuccess = {
    type: toast.TYPE.SUCCESS,
    className: "toast-success",
    ...options
};
const optionsError = {
    type: toast.TYPE.ERROR,
    ...options
};


function canBeRendered(content: any) {
    return typeof content === 'string' || typeof content === 'number' || typeof content === 'function';
};

export const ToastNotifyInfo = (message: string) => { canBeRendered(message) && toast(message, optionsInfo) }
export const ToastNotifySuccess = (message: string) => { canBeRendered(message) && toast(message, optionsSuccess) }
export const ToastNotifyError = (message: string) => { !toast.isActive(customId) && canBeRendered(message) && toast(message, optionsError) }