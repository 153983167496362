import React from 'react'
import "./style.scss";
import icons from '../../config/icons';


function SuccessIcon() {
  return (
    <div className="animation-ctn">
      <div className="icon icon--order-success svg">
        <img src={icons.successfullIcn} alt={icons.successfullIcn}/>
      </div>
    </div>
  )
}

export default SuccessIcon