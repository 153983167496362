import { useEffect, useRef, useState } from "react"
import icons from "../../config/icons";
import { getText } from "../../helpers/translateHelper";

interface CustomDropdownProps {
  options: string[];
  selectedOption?: any;
  onSelect: (selectedOption: string) => void;
  isDisable: boolean;
  optionKey: string;
  heading: string;
  selectedHeading:string
}

const CustomDropdown: React.FC<CustomDropdownProps> = ({ options, onSelect, selectedOption,selectedHeading, isDisable, optionKey, heading }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const icon: any = icons;

  useEffect(() => {
    const handleClickOutside = (event: any): void => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const handleToggleDropdown = (): void => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: string): void => {
    onSelect(option);
    setIsOpen(false);
  };

  return (
    <div className="custom-dropdown" ref={dropdownRef}>
      <div className={`dropdown-header flex justify-between pb-8 h-[30px] ${isDisable ? "pointer-events-none opacity-50" : ""}`} onClick={handleToggleDropdown}>
        {!!selectedOption[optionKey] ?
          <div className='selected-option h-full text-14 flex justify-between text-[#000000] items-center w-full '>
          { selectedHeading &&  !isOpen && <span className='selected-heading text-12 text-[#000000] font-[300]  capitalize absolute left-15 top-10 opacity-80'>
              {getText("SECURITY_QUESTION")} 
            </span>}
            <span className={`operator-icn flex items-center capitalize ${selectedHeading&&!isOpen?'pt-12':''}`}>
              {/* <img className='mr-10 w-20 h-20' src={icon[selectedOption?.imageUrl]} alt="icn" /> */}
              {selectedOption[optionKey]}
            </span>
            <span className='down-icn flex justify-end'>
              <img className='w-[16px]' src={icons.downIcn} alt="" />
            </span>
          </div>
          :
          <>
            <span className='operator-icn flex items-center capitalize'>
              {heading}
            </span>
            <span className='down-icn flex justify-end'>
              <img className='w-[16px]' src={icons.downIcn} alt="" />
            </span>
          </>
        }
      </div>
      {isOpen && (
        <div className="dropdown-options mt-6 border-[#DBDBDB]  border px-12 py-18 absolute left-0 right-0 w-[96%] mx-auto bg-white z-10">
          {options.map((option: any, i: number) => (
            <div
              key={i}
              className="dropdown-option flex items-center text-[#000000] text-14 mb-18 last:mb-0"
              onClick={() => handleOptionClick(option)}
            >
              {/* <span className='icn-ctn mr-10 w-20 h-20'>
                <img className='w-20 h-20' src={icon[option?.imageUrl]} alt="icn" />
              </span> */}
              
              {option[optionKey]}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default CustomDropdown;