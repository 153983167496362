import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { clearStore } from "../actions";

interface Props {
  bundles: any[];
  parsedBundles: any[];
  timeStamp: string;
  msisdn: string;
}

const initialState: Props = {
  bundles: [],
  parsedBundles: [],
  timeStamp: "",
  msisdn: "",
}

export const bundleSlice = createSlice({
  name: "bundleStore",
  initialState,
  reducers: {
    callFetchBundles: (state, action) => {
    },
    callIsSufficientBal: (state, action) => {
    },
    callFetchBundlesSuccess: (state, action) => {
      state.bundles = action.payload?.data;
      state.timeStamp = moment().format();
      state.msisdn = action.payload?.msisdn;
    },
    callFetchBundlesFailed: (state, action) => {
    },
    saveParsedBundles: (state, action) => {
      state.parsedBundles = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder //clears everything on 401
      .addCase(clearStore, (state) => {
        return initialState;
      })
      .addDefaultCase((state, action) => { })
  }
})

export const {
  callFetchBundles,
  callFetchBundlesSuccess,
  callFetchBundlesFailed,
  callIsSufficientBal,
  saveParsedBundles,
} = bundleSlice.actions;

export default bundleSlice.reducer;