import { PayloadAction } from "@reduxjs/toolkit";
import { put, takeLatest } from "redux-saga/effects";
import { ENDPOINTS } from "../../../config/endpoints";
import api from "../../../services/apiService";
import { callFetchTranslations, callFetchTranslationsSuccess } from "../../slices/translationSlice";

/**
 * Fetch all translations based on the current value of accept-language header
 * If no accept-language header specified then opco default should be returned by backend
 * @param action 
 */
export function* fetchTranslations(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  yield payload?.progressCallback();
  try {
    let response: any = yield api.request({
      method: "GET",
      url: ENDPOINTS.getTranslations,
      params: {
        'config-code': 'ui-translation'
      },
      hideLoader: payload.hideLoader
    });
    if (response?.data?.statusCode === 200 && !!response?.data?.result?.data) {
      yield put(callFetchTranslationsSuccess(response.data.result.data));
      payload?.successCallback();
    }
    else {
      payload?.errorCallback();
    }
  } catch (error) {
    payload?.errorCallback();
  }
}

export default function* root(): Generator {
  yield takeLatest(callFetchTranslations.type, fetchTranslations);
}
