import { createSlice } from "@reduxjs/toolkit";
import { clearStore } from "../actions";
import { IOperatorData } from "../../features/balance";

export interface ISendMoneyResponse {
  txnId: string;
  status: boolean;
  message: string;
  showRateMe: boolean;
  maxTransactionTime: number;
  timeDelay: number;
  statusCode: string;
  showMarkAsFavourite: boolean;
  authorizationRequired: boolean;
  showPGTimer: boolean;
  poolingRequired: boolean;
  isUserBlocked: boolean;
  recipientNumber: string;
  amountSend: string;
  transactionDateAndTime: string;
  operatorsData: IOperatorData[];
}

const initialState = {
  favourites: [],
  operatorsData: []
}

export const sendMoneySlice = createSlice({
  name: "sendMoney",
  initialState,
  reducers: {

    //send money api call reducer
    callSendMoney: (state, action: { payload: any, type: string }) => { },

    callSendMoneySuccess: (state, action) => { },

    callSendMoneyFailure: (state, action) => { },

    //call get user info
    callGetUserName: (state, action) => { },

    //call get recepient am user profile
    callRecepientAMUserProfile: (state, action) => { },
    callGetOperatorsData: (state, action) => { },
    callGetOperatorsDataSuccess: (state, action) => {
      state.operatorsData = action.payload;
    },
    callGetMsisdnOperator: (state, action) => { }
  },
  extraReducers: (builder) => {
    builder //clears everything on 401
      .addCase(clearStore, (state) => {
        return initialState;
      })
      .addDefaultCase((state, action) => { })
  }
});

export const {
  callSendMoney,
  callSendMoneySuccess,
  callSendMoneyFailure,
  callGetUserName,
  callRecepientAMUserProfile,
  callGetOperatorsData,
  callGetOperatorsDataSuccess,
  callGetMsisdnOperator
} = sendMoneySlice.actions;

export default sendMoneySlice.reducer;
