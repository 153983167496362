import { PayloadAction } from "@reduxjs/toolkit";
import { put, select, takeLatest } from "redux-saga/effects";
import { customerLookup, fetchBillerCategories, fetchBillerCategoriesFailed, fetchBillerCategoriesSuccess, fetchBillerInfo, fetchBillerInfoFailed, fetchBillerInfoSuccess, fetchBillers, fetchBillersFailed, fetchBillersSuccess, payBillProcessTrxn } from "../../slices/payBillSlice";
import api from "../../../services/apiService";
import { ENDPOINTS } from "../../../config/endpoints";
import { ToastNotifyError } from "../../../components/ToastNotify";
import { RootState } from "../..";


function* getBillerCategories(action: PayloadAction<any>): Generator {
  try {
    const response: any = yield api.request({
      method: "GET",
      url: ENDPOINTS.PAY_BILL.fetchCategories
    });
    if (!!response?.data?.result?.data?.length && response?.data?.statusCode === 200) {
      yield put(fetchBillerCategoriesSuccess(response.data.result.data));
    } else {
      yield put(fetchBillerCategoriesFailed([]));
      ToastNotifyError(response?.data?.message);
    }
  } catch (error) {
    console.log(error);
  }
}

function* getBillers(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response: any = yield api.request({
      method: "GET",
      url: ENDPOINTS.PAY_BILL.fetchBillers(payload.code)
    });
    if (!!response?.data?.result?.data?.length && response?.data?.statusCode === 200) {
      !!action?.payload?.callback && action.payload.callback(response.data);
      yield put(fetchBillersSuccess(response.data.result.data));
    } else {
      yield put(fetchBillersFailed([]));
      !!action?.payload?.callback && action.payload.callback([]);
      // ToastNotifyError(response?.data?.message);
    }
  } catch (error) {
    console.log(error);
  }
}

function* getBillerInfo(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response: any = yield api.request({
      method: "GET",
      url: ENDPOINTS.PAY_BILL.fetchBillerInfo(payload.code)
    });
    if (response?.data?.statusCode === 200) {
      !!action?.payload?.callback && action.payload.callback(response.data);
      yield put(fetchBillerInfoSuccess(response.data.result.data));
    } else {
      yield put(fetchBillerInfoFailed([]));
      ToastNotifyError(response?.data?.message);
    }
  } catch (error) {
    console.log(error);
  }
}

function* getCustomerLookup(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response: any = yield api.request({
      method: "POST",
      url: ENDPOINTS.PAY_BILL.customerLookup(payload.code),
      data: payload.dataToSend
    });
    if (response?.data?.statusCode === 200) {
      !!action?.payload?.callback && action.payload.callback(response.data);
      yield put(fetchBillerInfoSuccess(response.data.result.data));
    } else {
      yield put(fetchBillerInfoFailed([]));
      ToastNotifyError(response?.data?.message);
    }
  } catch (error) {
    console.log(error);
  }
}

function* processTrxn(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  const invalidAMPinStatuses = (yield select((state: RootState) => state.config?.basicConfig?.invalidAMPinStatuses)) as number[];
  try {
    const response: any = yield api.request({
      method: "POST",
      url: ENDPOINTS.PAY_BILL.processTrxn,
      data: payload.data
    });
    if (response?.data?.statusCode === 200) {
      !!action?.payload?.successCallback && action.payload.successCallback(response.data);
      yield put(fetchBillerInfoSuccess(response.data.result.data));
    } else if (response && !invalidAMPinStatuses?.includes(response?.data?.statusCode)) {
      yield put(fetchBillerInfoFailed([]));
      action?.payload?.errorPageCallback?.(response?.data?.message);
    }
    else {
      yield put(fetchBillerInfoFailed([]));
      !!action?.payload?.errorCallback && action.payload.errorCallback(response?.data?.message);
    }
  } catch (error) {
    console.log(error);
  }
}

export default function* root(): Generator {
  yield takeLatest(fetchBillerCategories.type, getBillerCategories);
  yield takeLatest(fetchBillers.type, getBillers);
  yield takeLatest(fetchBillerInfo.type, getBillerInfo);
  yield takeLatest(customerLookup.type, getCustomerLookup);
  yield takeLatest(payBillProcessTrxn.type, processTrxn);
}