import { all } from "redux-saga/effects";
import balanceSaga from "./balance";
import configSaga from "./config";
import homeSaga from "./home";
import loginSaga from "./login";
import sendMoneySaga from "./sendMoney";
import globalSaga from "./global";
import bundleSaga from "./bundle";
import payBillSaga from "./payBill";
import translationSaga from "./translation";
import appSettingsSaga from "./appSettings";
import amSaga from "./am";
import withdrawCashSaga from "./withdrawCash";
import paymentSaga from "./payment";


export default function* RootSaga() {
    yield all([loginSaga(), balanceSaga(), configSaga(), homeSaga(), sendMoneySaga(), globalSaga(), bundleSaga(), payBillSaga(), translationSaga(),
    appSettingsSaga(), withdrawCashSaga(), paymentSaga(), amSaga()]);
}