import { createSlice } from "@reduxjs/toolkit";

interface Props {
  billerCategories: any;
  billers: any;
};

const initialState: Props = {
  billerCategories: [],
  billers: []
};

export const payBillSlice = createSlice({
  name: "payBillStore",
  initialState,
  reducers: {
    fetchBillerCategories: (state, action) => {

    },
    fetchBillerCategoriesSuccess: (state, action) => {
      state.billerCategories = action.payload;
    },
    fetchBillerCategoriesFailed: (state, action) => {

    },
    fetchBillers: (state, action) => {
    },
    fetchBillersSuccess: (state, action) => {
      state.billers = action.payload;
    },
    fetchBillersFailed: (state, action) => {

    },
    fetchBillerInfo: (state, action) => {
    },
    fetchBillerInfoSuccess: (state, action) => {
    },
    fetchBillerInfoFailed: (state, action) => {
    },
    customerLookup: (state, action) => {
    },
    customerLookupSuccess: (state, action) => {
    },
    customerLookupFailed: (state, action) => {
    },
    payBillProcessTrxn: (state, action) => {
    },
    payBillProcessTrxnSuccess: (state, action) => {
    },
    payBillProcessTrxnFailed: (state, action) => {
    },
  }
});

export const {
  fetchBillerCategories,
  fetchBillerCategoriesSuccess,
  fetchBillerCategoriesFailed,
  fetchBillers,
  fetchBillersSuccess,
  fetchBillersFailed,
  fetchBillerInfo,
  fetchBillerInfoSuccess,
  fetchBillerInfoFailed,
  customerLookup,
  customerLookupSuccess,
  customerLookupFailed,
  payBillProcessTrxn,
  payBillProcessTrxnSuccess,
  payBillProcessTrxnFailed
} = payBillSlice.actions;

export default payBillSlice.reducer;