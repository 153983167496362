import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { put, select, takeLatest } from "redux-saga/effects";
import { RootState } from "../..";
import { ToastNotifyError } from "../../../components/ToastNotify";
import { ENDPOINTS } from "../../../config/endpoints";
import { SOME_ERR, errors, fallbackTranslations } from "../../../data/constants";
import api from "../../../services/apiService";
import { setCustomLoader } from "../../slices/globalSlice";
import * as sendMoneySlice from "../../slices/sendMoneySlice";

function* sendMoney(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response = (yield api.request({
      method: "POST",
      url: ENDPOINTS.processTxn(),
      data: payload.data,
    })) as AxiosResponse;
    const invalidAMPinStatuses = (yield select((state: RootState) => state.config?.basicConfig?.invalidAMPinStatuses)) as number[];
    if (response && response?.data?.statusCode === 200) {
      yield put(sendMoneySlice.callSendMoneySuccess(response));
      !!action?.payload?.successCallback && action.payload.successCallback(response?.data?.message);
    }
    else if (response && !invalidAMPinStatuses?.includes(response?.data?.statusCode)) {
      yield put(sendMoneySlice.callSendMoneyFailure(response));
      action?.payload?.errorPageCallback?.(response?.data?.message);
    }
    else {
      yield put(sendMoneySlice.callSendMoneyFailure(response));
      !!action?.payload?.errorCallback && action.payload.errorCallback(response?.data?.message);
    }
  } catch (error) {
    console.log("Something went wrong", error);
    yield put(sendMoneySlice.callSendMoneyFailure(error));
  }
}


function* getSendMoneyUserName(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response: any = yield api.request({
      method: "POST",
      url: ENDPOINTS.HOME.getUserInfo(),
      data: payload.data,
    });

    if (!!response?.data && response?.data?.statusCode === 200) {
      !!action?.payload?.callback && action.payload.callback(response?.data?.result?.customerName);
    }
    else {
      yield put(setCustomLoader(false));
      ToastNotifyError(response?.data?.message);
    }
  } catch (error) {
    yield put(setCustomLoader(false));
    console.log("Something went wrong", error);
  }
}

function* getRecepientAMUserProfile(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response: any = yield api.request({
      method: "POST",
      url: ENDPOINTS.HOME.getAMProfile(),
      data: payload.data,
    });
    if (response?.status === 200 && response?.data?.statusCode === 200 && response?.data?.result?.userId && !response?.data?.result?.userBarred) {
      action?.payload?.callback?.();
    }
    else if (response?.data?.result?.userBarred) {
      action?.payload?.errorCallback?.(response?.data?.result?.userBarred);
    }
    else {
      action?.payload?.errorCallback?.(response?.data?.message);
    }
  } catch (error) {
    yield put(setCustomLoader(false));
    ToastNotifyError(fallbackTranslations[SOME_ERR]);
  }
}

function* fetchOperatorsData(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response: any = yield api.request({
      method: "GET",
      url: ENDPOINTS.SEND_MONEY.getOperatorData
    });
    if (response?.status === 200 && response?.data?.statusCode === 200 && !!response?.data?.result?.operators?.length) {
      yield put(sendMoneySlice.callGetOperatorsDataSuccess(response?.data?.result?.operators));
      action?.payload?.callback?.(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

function* fetchMsidnOperatorData(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response: any = yield api.request({
      method: "POST",
      url: ENDPOINTS.SEND_MONEY.getNumberOperator,
      data: payload.data,
      hideLoader: payload.hideLoader
    });
    
    action?.payload?.callback?.(response?.data?.result);
    
  } catch (error) {
    console.log(error);
  }
}

export default function* root(): Generator {
  yield takeLatest(sendMoneySlice.callSendMoney.type, sendMoney);
  yield takeLatest(sendMoneySlice.callGetUserName.type, getSendMoneyUserName);
  yield takeLatest(sendMoneySlice.callRecepientAMUserProfile.type, getRecepientAMUserProfile);
  yield takeLatest(sendMoneySlice.callGetOperatorsData.type, fetchOperatorsData);
  yield takeLatest(sendMoneySlice.callGetMsisdnOperator.type, fetchMsidnOperatorData);
}
