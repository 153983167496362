

//variant 2 header component
export const Header = ({clickHandler, text, icon} : IHeaderProp) => {
    


    return <div className= "back-btn-ctn bg-primaryRed text-white text-16 py-6 px-12 h-52 inline-flex items-center w-full min-w-[66px]" onClick={clickHandler}>
        <img src={icon} alt="icn" className="mr-10" />
        <p className="text-14 text-white font-medium">{text}</p>
    </div>
}

interface IHeaderProp {
  clickHandler : () => void,
  text: string,
  icon: string
}
