import { useEffect } from 'react';

const useLockScrollOnInputFocus = () => {
  useEffect(() => {
    const handleFocus = () => {
      document.body.style.overflow = 'hidden';
    };

    const handleBlur = () => {
      document.body.style.overflow = '';
    };

    // Add event listeners to all input elements
    const inputElements = document.querySelectorAll('input, textarea');
    inputElements.forEach((input) => {
      input.addEventListener('focus', handleFocus);
      input.addEventListener('blur', handleBlur);
    });

    // Remove event listeners on component unmount
    return () => {
      inputElements.forEach((input) => {
        input.removeEventListener('focus', handleFocus);
        input.removeEventListener('blur', handleBlur);
      });
    };
  }, []); // Empty dependency array ensures the effect runs only once on mount

  return null; // Hooks must return something, null in this case as it's not rendering anything
};

export default useLockScrollOnInputFocus;
