import { createSlice } from "@reduxjs/toolkit";
import { IPaymentOptions } from "../../config/commonInterfaces";

export interface IPaymentStore {
  optionsObj: Record<string, IPaymentOptions[]>;
}

const initialState: IPaymentStore = { optionsObj: {} };

export const payment = createSlice({
  name: "paymentStore",
  initialState,
  reducers: {
    callFetchPaymentOptions: (state, action) => {
    },

    /**
     * Save newly fetched payment options
     * @param state 
     * @param action 
     */
    callFetchPaymentOptionsSuccess: (state, action) => {
      if (!state?.optionsObj)
        state = initialState;
      const transactionType = action.payload.transactionType;
      const data = action.payload.data;
      state.optionsObj[transactionType] = data;
    },

    //trigger mpesa validation
    callMPESAValidationApi: (state, action) => {
    },
    //trigger mpesa enquiry api
    callMPESAEnquiryApi: (state, action) => {
    },

    callTransactionAmountValidation: (state, action) => {
    }
  }
})

export const {
  callFetchPaymentOptions,
  callFetchPaymentOptionsSuccess,
  callMPESAValidationApi,
  callMPESAEnquiryApi,
  callTransactionAmountValidation
} = payment.actions;

export default payment.reducer;