import { PayloadAction } from "@reduxjs/toolkit";
import { put, takeLatest } from "redux-saga/effects";
import { ToastNotifyError } from "../../../components/ToastNotify";
import { ENDPOINTS } from "../../../config/endpoints";
import api from "../../../services/apiService";
import {
  callLaunchConfigApi,
  launchConfigApiFailed,
  launchConfigApiSucess,
} from "../../slices/configSlice";



function* fetchLaunchConfig(action: PayloadAction<any>): Generator {
  try {
    const response: any = yield api.request({
      method: "GET",
      url: ENDPOINTS.CONFIG.launchConfig,
      hideLoader: action?.payload?.hideLoader
    });
    if (!!response?.data && response?.status === 200) {
      yield put(launchConfigApiSucess(response.data?.result));
      !!action?.payload?.callback && action.payload.callback(response.data?.result?.data?.[0]);
    } else {
      ToastNotifyError(response?.data?.message);
      yield put(launchConfigApiFailed(response));
    }
  }
  catch (error) {
    console.log("Something went wrong", error);
    yield put(launchConfigApiFailed(error));
  }
}

export default function* root(): Generator {
  yield takeLatest(callLaunchConfigApi.type, fetchLaunchConfig);
}
