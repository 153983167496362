import { useSelector } from "react-redux";
import icons from "../../config/icons";
import { getText } from "../../helpers/translateHelper";
import Modal from "../Modal/Modal";
import { RootState } from "../../Redux";

interface Props {
  closeModal: (...args: any[]) => void;
}

const AMBarredPopup = ({ closeModal }: Props) => {
  const locateUsLink = "https://www.airtelkenya.com/storeLocator/",
    basicConfig = useSelector((state: RootState) => state.config.basicConfig);
  return (
    <Modal
      isOpen={true}
      className="top-[40%]"
      onClose={() => { }}
    >
      <div className="am-wallet-barred bg-white rounded-xl relative p-20 pt-30 mx-16 flex flex-col">
        <div className="close-icn absolute right-8 top-8" onClick={closeModal}>
          <img className="w-24 h-24" src={icons.closeIcn} alt="close-icn" />
        </div>
        <div className="main-ctn flex ">
          <div className="logo-ctn relative top-[-3px]">
            <img className="w-[45px] h-[45px]" src={icons.amBarred} alt="am-barred" />
          </div>
          <div className="main-heading ml-10 text-16 text-[#242224] w-[90%]">
            {getText("AM_BARRED_TEXT")}
          </div>
        </div>
        <div className="cta-ctn flex justify-between mt-16 text-15 font-[800] text-[#3482F5]">
          <a className="locate-shp-cta" target="_blank" href={locateUsLink}>{getText("LOCATE_SHOP")}</a>
          <a className="call-cc-cta" href={`tel:${basicConfig.ussdAMCode}`}>{getText("CALL_CC")}</a>
        </div>
      </div>
    </Modal>
  )
}

export default AMBarredPopup;