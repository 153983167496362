import { useEffect, useRef } from 'react';
import moment from 'moment';
const useDelayedFunction = (
  delay: number,
  timestamp: string,
  func: () => void,
  flag: boolean
): void => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    const currentTime = moment();
    const targetTime = moment(timestamp).add(delay, 'milliseconds');
    const timeDifference = targetTime.diff(currentTime);
    if (timeDifference > 0) {
      timeoutRef.current = setTimeout(func, timeDifference);
    } else {
      func();
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [delay, func, timestamp]);
};
export default useDelayedFunction;