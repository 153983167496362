import { useEffect, useRef } from "react";

interface Props {
  onIdle?: any;
  idleTime?:any
}

export const useIdle = ({ onIdle, idleTime }: Props) => {
  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    const handleWindowEvents = () => {
      clearTimeout(timeoutRef.current);

      timeoutRef.current = setTimeout(() => {
        //handles what happens when the user is idle
        onIdle();
      }, 1000 * 60 * idleTime);
    };

    // listen for specific window events to ensure the user is still active
    window.addEventListener("mousemove", handleWindowEvents);
    window.addEventListener("keydown", handleWindowEvents);
    window.addEventListener("click", handleWindowEvents);
    window.addEventListener("scroll", handleWindowEvents);

    handleWindowEvents();

    // cleanup function
    return () => {
      window.removeEventListener("mousemove", handleWindowEvents);
      window.removeEventListener("keydown", handleWindowEvents);
      window.removeEventListener("click", handleWindowEvents);
      window.removeEventListener("scroll", handleWindowEvents);
    };
  }, []);
}