import { PayloadAction } from "@reduxjs/toolkit";
import { put, takeLatest } from "redux-saga/effects";
import { ToastNotifyError } from "../../../components/ToastNotify";
import { ENDPOINTS } from "../../../config/endpoints";
import { parseBundleData } from "../../../features/SelectBundleType/helper/bundleHelper";
import api from "../../../services/apiService";
import { callFetchBundles, callFetchBundlesFailed, callFetchBundlesSuccess, callIsSufficientBal } from "../../slices/bundleSlice";

function* fetchBundles(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    let response: any = yield api.request({
      method: "POST",
      url: ENDPOINTS.BUNDLE.fetchBundles(),
      data: payload.data,
      hideLoader: payload.hideLoader
    });
    if (!!response?.data && response?.status === 200) {
      yield put(callFetchBundlesSuccess({ data: response.data, msisdn: payload.data?.recipientMsisdn }));
      parseBundleData(response.data?.result?.category);
      !!action?.payload?.callback && action.payload.callback(response.data);
    } else {
      !!response.data?.message && ToastNotifyError(response.data.message);
      yield put(callFetchBundlesFailed(response));
    }
  } catch (error) {
    console.log(error);
    // ToastNotifyError("Something went wrong");
    yield put(callFetchBundlesFailed(error));
  }
}

function* isSufficientBal(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response: any = yield api.request({
      method: "POST",
      url: ENDPOINTS.AM.isSufficientBal(),
      data: payload.data
    });
    if (response?.data?.statusCode === 200) {
      action?.payload?.callback?.(response?.data?.result?.isSufficient)
    } else {
      action?.payload?.errorCallback?.(response.data?.message);
    }
  } catch (error) {
    action?.payload?.errorCallback?.();
  }
}

export default function* root(): Generator {
  yield takeLatest(callFetchBundles.type, fetchBundles);
  yield takeLatest(callIsSufficientBal.type, isSufficientBal);
}
