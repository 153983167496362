import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { clearStore } from "../actions";

interface Props {
    availableFeatures: any;
    menuOptions: any[];
    subMenuOption: any;
    betaFeature: any;
}

const initialState: Props = {
    availableFeatures: {},
    menuOptions: [],
    subMenuOption: {},
    betaFeature: []
}

export const featureSlice = createSlice({
    name: "featureStore",
    initialState,
    reducers: {
        updateAvailableFeatures: (state, action) => {
            state.availableFeatures = action.payload;
        },
        updateMenuOptions: (state, action) => {
            state.menuOptions = action.payload;
        },
        updateSubMenuOptions: (state, action) => {
            state.subMenuOption = action.payload;
        },
        updateBetaFeatures: (state, action) => {
            state.betaFeature = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder //clears everything on 401
            .addCase(clearStore, (state) => {
                return initialState;
            })
            .addDefaultCase((state, action) => { })
    }
})

export const {
    updateAvailableFeatures,
    updateMenuOptions,
    updateSubMenuOptions,
    updateBetaFeatures
} = featureSlice.actions;

export default featureSlice.reducer;