import { useEffect } from "react";
import { useSelector } from "react-redux";
import icons from "../../../../config/icons";
import { getText } from "../../../../helpers/translateHelper";
import { sendGA4Event } from "../../../../services/ga4";
import { RootState } from "../../../../Redux";
import moment from "moment";
import { ACTIVE_PACKS, BALANCE, DATE_FORMAT, EXPIRY, NO_PACK_TEXT } from "../../../../data/constants";
import { useNavigate } from "react-router-dom";
import { balanceRoute } from "../../../../routes/routeConstants";


const ActivePacks = () => {
  const activePacks = useSelector((state: RootState) => state.balance.balance.activePacks) || [],
    navigate = useNavigate();
  
  useEffect(()=>{
    sendGA4Event({ category: "balance", action: "gsm_activepacks_view" });
  },[])

  function gotoBack() {
    navigate(balanceRoute.BALANCE);
  }

  return (
    <div className="active-packs-ctn bg-[#FAFAFA] z-50 w-[100vw] h-[100vh] relative">
      <div className="active-pack-header fixed left-0 right-0 top-0 header py-16 px-10 text-white bg-[#BE1C30] text-14">
        <div className="main-header flex items-center" onClick={gotoBack}>
          <img className="w-16 mr-14" src={icons.whiteleftArrow} alt="white-arrow" />
          {getText(ACTIVE_PACKS)}
        </div>
      </div>

      {!!activePacks.length ?
        <>
          <div className="active-pack-body absolute bg-white rounded-t-[10px]  shadow-md py-16 px-12 m-12 top-[53px] right-0 left-0">
            <div className="pack-card">
              {activePacks.map((pack: any, i: number) => {
                return (

                  <div key={i} className="active-pack mb-16 last:mb-0 text-[#242224] bg-[#F4F4F4] p-16 rounded-[10px]">
                    <div className="pack-title-ctn text-16 flex justify-between">
                      <div className="pack-name-ctn">
                        {pack.title}
                      </div>
                      <div className="pack-expiry-ctn">
                        {getText(EXPIRY)}
                      </div>
                    </div>
                    <div className="pack-bal-ctn text-14 flex justify-between items-baseline">
                      <div className="pack-balance mt-8">
                        <span className="balance-heading text-14 text-[#E30000]">
                          {getText(BALANCE)} :{" "}
                        </span>
                        {Number(pack.total)} {pack.unit}
                      </div>
                      <div className="expiry-time-ctn">
                        {moment.utc(pack.expiry).local().format(DATE_FORMAT)}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div></div>
        </>
        :
        <div className="no-active-packs-ctn pt-100 text-center text-[#E30000]">
          <img className="w-[60px] inline-block" src={icons.no_file_icon} alt={icons.no_file_icon} />
          <p className="text-20 text-[#242224] mt-20"> {getText(NO_PACK_TEXT)}</p>
        </div>

      }

    </div>
  )
}

export default ActivePacks;