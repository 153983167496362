import _ from "lodash";
import { ISimpleBalance } from "../config/interface";
import moment from "moment";
import { store } from "../../../Redux";
import { callCheckGSMBalance } from "../../../Redux/slices/balanceSlice";
import { createCheckGSMBalancePayload } from "../config/balancePayloads";
import { getPhoneNumberFromLs } from "../../../utils/commonUtils";

// sort input bundle balance by expiry date time
export function sortISimpleBalanceDetailsByDateTime(
  details: ISimpleBalance["details"],
  objDTFormat?: string
) {
  if (objDTFormat) {
    return _.sortBy(details, (kv) =>
      moment(kv?.value?.expiry, objDTFormat)
    );
  }
  console.warn("unsafe dateTime conversion !");
  return _.sortBy(details, (kv) => moment(kv?.value?.expiry));
}

export function fetchBalance(hideLoader=false) {
  let msisdn = getPhoneNumberFromLs() || "";
  store.dispatch(callCheckGSMBalance(createCheckGSMBalancePayload(msisdn, hideLoader)));
}